export const GOOGLE_API_KEY = 'AIzaSyCW8cX-P1RzOFPK-nSX5NPLMQIh2k5BT0E';

export const DISTANCES = [
  { label: '1 km', value: 1 },
  { label: '5 km', value: 5 },
  { label: '10 km', value: 10 },
  { label: '20 km', value: 20 },
  { label: '25 km', value: 25 },
  { label: '50 km', value: 50 },
  { label: '100 km', value: 100 },
];

export const CLAIM_TYPES = [
  { label: 'Both', value: 0 },
  { label: 'Residential', value: 1 },
  { label: 'Commercial', value: 2 },
];

export enum ClaimType {
  Residential = 1,
  Commercial = 2,
};

export const BATCH_SIZE = 25;